import {  useTheme, Flex } from "@aws-amplify/ui-react";


export function Header() {
  const { tokens } = useTheme();

  return (
    



<Flex
  direction="row"
  justifyContent="center"
  alignItems = "center"
  textAlign="center"
  backgroundColor="#FECD45"

>
  <span className = "navBarTitleAuth" style={{ textDecoration: 'none' }} >NANO<span  style={{ color: '#2568FB' }}  >CLOUD</span>SERVERS</span>
</Flex>
  


  );
}
