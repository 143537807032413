import React, { useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import appConfig from './AppConfig';
import { Flex } from '@aws-amplify/ui-react';
//import {  toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { API } from 'aws-amplify';
//import { generateUtilityClass } from '@mui/material';


//1.8a

function PaypalTime(props) { 

  
 
 
  
  const apiNamepaypal = 'ncspaypal'; // replace this with your api name.
  const path = '/items'; //replace this with the path you have configured on your API
  


  const [state, setState] = useState("--");

  function stateChange(f) {
    setState(f);
    props.funct1b(f);
    //props.ustanding = f;
    };
    



function addTimeTest() {

  addSubButton('TEST000000')
};


function addSubButton(data) {





        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        //myHeaders.append("Content-Type", "text/plain");
        
        var raw = JSON.stringify({"uname": props.uname,"act": "addTime_"+ appConfig.apiname,"dat": data});
    

      var myInit = {
        body: raw,
        headers: {
          'Content-Type': 'application/json'}, 
          redirect: 'follow',
      };
      API.post(apiNamepaypal, path, myInit)
      .catch((error) => {
        alert(appConfig.appname + " PAYPAL API ERROR: " + error.response) 
      });

     stateChange("enabled")
        
      }
    
      // production "client-id": "", 
      //sandbox "client-id": "Acjn36fbpAa_0ctQxBn1Ipgkqj-GpXNkMcD0TwGXdCCQCYIMzaTRIVXRtUlSX7DB5Kz9zlIoCDeuqBDy", 
      //  <button style={{ border: "none",color: "black", marginTop: "0px", marginBottom: "0px" , backgroundColor: "#4d4d4d", color: "white" , display: "inline-block", fontSize: "12px", height: "21px", width: "150px" }} onClick={addTimeButton}>TEST PAYPAL</button>

      //P-1XC40242B15636748MS4356I


      const SubscriptionButtonWrapper = () => {
        var h = Math.round(window.innerWidth / 30);

          if (h > 50) {  h = 50}
          if (h < 25) {  h = 25}

        //var h = 50
        return (

          <Flex
          direction="column"
    gap="1vw"
    
    textAlign="center"
    alignItems="center"
    justifyContent= "center"
          marginTop='2vw'
          
          >

Monthly Subscription <br></br>$19.99 (+tax)
       
<Flex
          direction="column"
          gap="0"
          
          textAlign="center"
          alignItems="center"
          justifyContent= "center"
                //marginTop='.1vw'
                
>

        <PayPalButtons
        createSubscription={(data, actions) => {
          return actions.subscription
            .create({
              plan_id: "P-85E79057CJ276903NMWNVFVQ",
            });
        }}
        style={{
       //   label: "subscribe",
          layout: "horizontal",
          tagline: false,
          height: h,
          
       //   color: "white"
        }}
        onApprove={(data, actions) => {
          return actions.subscription.capture(  addSubButton(data)  );
         }}
         /> 
         </Flex>
        
        </Flex>
        
        );
      };


   



       return (<>
<Flex 
 direction="column"
 textAlign="center"
 alignItems="center"
//maxWidth = "150px"
//minHeight = "96px"
//paddingTop="1vw"
gap = "0"
fontSize="2vw"
>




<PayPalScriptProvider
			options={{
				"client-id": "ARTGesgzUqj887zz644Has2xSMpnG4m5rAQTZ9Yo07PNKuyr-5_LEI8wnJtMkKLns1cc8s_XKc_v3AEM",  
				components: "buttons",
				intent: "subscription",
				vault: true,
			}}
		>
			<SubscriptionButtonWrapper /> 
		</PayPalScriptProvider>





  
</Flex>    

</>
);

}

export default PaypalTime;









