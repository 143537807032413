//v1.8a
const appConfig = {
    "ver": " ",
    "appname": "Nano Cloud Servers",  
    "webtitle": "NCS Portal", 
    "apiname": "ncs", 
    "websitename": "nanocloudservers.com", 

    "debugHide":true,


};

export default appConfig;
