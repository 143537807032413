import { Flex, Text, useTheme } from "@aws-amplify/ui-react";

export function Footer() {
  const { tokens } = useTheme();

  return (
    <Flex justifyContent="center" padding={tokens.space.medium}>
        <span style={{fontSize:'calc(1vw + 6px)'}}><Text>&copy; All Rights Reserved</Text></span>
    </Flex>
  );
}
