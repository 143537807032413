import React, { useState, useEffect, useRef} from 'react';
import appConfig from './AppConfig';
import logo from './785.svg';
import { API } from 'aws-amplify'
//import PaypalTime from "./PaypalTime";
import {
  Flex,
//  Heading,
//  Image,
//  View,
} from "@aws-amplify/ui-react";
//import { Line } from 'rc-progress';
//import {  toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
//import PaypalSubs from "./PaypalSubs";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
//import { Amplify } from 'aws-amplify';
//import awsExports from './aws-exports';
//Amplify.configure(awsExports);


//v1.8b

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}








function StatusTime(props) { 

  

  const [fact2, setFact2] = useState("");




  const [open, setOpen] = React.useState(false);
  
  const handleClickToOpen = () => {
    setOpen(true);
  };
  
  const handleToClose = () => {
    setOpen(false);
  };
  
  const [open4, setOpen4] = React.useState(false);

  
  const handleClickToOpen4 = () => {
    setOpen4(true);
  };

  const handleToClose4 = () => {
    setOpen4(false);
  };
  
  const handleToClose4a = () => {
    setOpen4(false);
    goReset()
  };
  
  const handleToClose4b = () => {
    setOpen4(false);
    goBackup()
  };
  
  const handleToClose4c = () => {
    setOpen4(false);
    goRestore()
  };
  


const [state, setState] = useState("0");
const [state2a, setState2a] = useState("0");


  const [counter, setCounter] = useState(0);
 
  const [tic, setTic] = useState(0);

  const [loadTic, setLoadTic] = useState(0);

  //function notify(note) {toast.success(note);};
  //function notify(note) {toast.info(note)};
  //function enotify(note) {toast.error(note)};
  //function enotify(note) {toast.warning(note)};
  
 
  function stateChange(f) {
    setState(f);
    props.funct(f);
//    to set ustanding
};
  



const [uname, setUname] = useState("");
const [skipFact, setSkipFact] = useState("0");
const [loader, setLoader] = useState("");



   const apiName = 'apibc7ea7ab'; // replace this with your api name.
const path = '/items'; //replace this with the path you have configured on your API







  function fetchFact2() {
    //alert('ff2 ' + props.value);


    
    var raw = JSON.stringify({"uname": props.value,"act": "status_"+ appConfig.apiname});
    var myInit = {
      body: raw,
      headers: {
        'Content-Type': 'application/json'}, 
        redirect: 'follow',
    };

     API.post(apiName, path, myInit)
     .then((response) => {
      setFact2(response);
    

      tmpstr = response.substring( response.indexOf("|") + 1,response.lastIndexOf("~") );
      tmpustanding = tmpstr.split("!")[5];
   
      if (tmpustanding !== props.ustanding) {
       /////////////////////////////////////////// stateChange('disabled');setFact2('');setSkipFact('0');
      } else {



        }

      })
       .catch((error) => {
        alert(appConfig.appname + " STATUS-8 API ERROR: " + error.message + ' ' + error.response) 
      })
    };




    
  var tmpcnt = 0;

   tmpcnt = 5000;

  



  useInterval(() => {
   //setUname(props.value);

   if (props.value === "--") {
    //skip unmae & fetch set
  } else {

if (props.ustat === "--") {
//ERROR in APP.JS
//alert('skipped fetch')
} else {
  

  if (props.ustanding === 'disabled') {
//skip

//alert('skip')
  } else {

    //Skip fact for 2 calls
    if (skipFact === "0" ) {
      //skip once
      setSkipFact('1');
      //alert('skipped once')
    } else if (skipFact === '1') {
      //skip twice
      setSkipFact('2');
      //alert('skipped twice') 
    } else {
      fetchFact2(); 

      
    if (loader === "starting" ) {
      setLoadTic( loadTic + 1 )
    };

    if (loader === "stopping" ) {
      setLoadTic( loadTic + 1 )
    }

    if (loader === "backing up" ) {
      setLoadTic( loadTic + 1 )
    }

    if (loader === "resetting" ) {
      setLoadTic( loadTic + 1 )
    }


    if (loader === "restoring" ) {
      setLoadTic( loadTic + 1 )
    }


    };
  }

};

  };
    if (counter === 10) {
      setCounter(1);
      if (tic === 100000) { 
        setTic(1);
      } else {
        setTic(tic + 1);
      }
    } else {
      setCounter(counter + 1);
    }
  }, tmpcnt);





  function startButton() {
    //alert('STR-B:' + props.value + '-' + appConfig.apiname );


    var raw = JSON.stringify({"uname": props.value,"act": "start_"+ appConfig.apiname});

    var myInit = {
      body: raw,
      headers: {
        'Content-Type': 'application/json'}, 
        redirect: 'follow',
    };
    API.post(apiName, path, myInit)
    .catch((error) => {
      alert(appConfig.appname + " START API ERROR: " + error.message + ' ' + error.response)   }  );
    };
  
  
  function stopButton() {//alert('STO-B');
    var raw = JSON.stringify({"uname": props.value,"act": "stop_"+ appConfig.apiname});
       var myInit = {
      body: raw,
      headers: {
        'Content-Type': 'application/json'}, 
        redirect: 'follow',
    };
    API.post(apiName, path, myInit)
    .catch((error) => {
      alert(appConfig.appname + " STOP API ERROR: " + error.message + ' ' + error.response) 
   // .then((response) => {setFact2(response)
    }
   );



  };
  


  
function goButton() {
 //alert('button ' + fact2);
  //////////////////////////////////////


if (fact2 === "") {
  var tmpstat = props.ustat;  
  var tmpustanding = props.ustanding;
  var tmpuready = props.uready;

} else {

   tmpstr = fact2.substring( fact2.indexOf("|") + 1,fact2.lastIndexOf("~") );
   tmpstat = tmpstr.split("!")[0];
   tmpustanding = tmpstr.split("!")[5];
   tmpuready = tmpstr.split("!")[1];

};



  if( tmpustanding === "enabled" ) {


    if (tmpuready === 'no') {
      //SKIP Button Action

    } else {
   

    if (tmpstat === 'Stopped') {

      
   // if (fact2 === "") {
   //     alert( 'Account creation underway - Please try again in a few seconds')
   // } else {
        setLoadTic(0);
        setLoader("starting");
        startButton() 
   //   }

    } else if (tmpstat === 'Running' ) {
      setLoadTic(0);
     setLoader("stopping");
      stopButton();
 //     notify('Destroying Cloud PC');
    } else {
   //   alert("DISABLED (ST-350)")
    }

  }



  } else {
      ///
     // alert("DISABLED (ST-359)")
  }

  
 

};


function goBlank() {};
 
  



function goOpt1() {


  handleClickToOpen4();
  
  
   };
  



function downloadTxtFile() {
 

let datecode = "connect";

  if (fact2 === "") {
    var tmpuip = props.uip;
   // var tmpupass = props.upass;
  } else {
    var tmpstr = fact2.substring( fact2.indexOf("|") + 1,fact2.lastIndexOf("~") );
    tmpuip = tmpstr.split("!")[17];

  };

  const element = document.createElement("a");
  const txt = "auto connect:i:1\nfull address:s:" + tmpuip +"\nusername:s:Administrator";

 //navigator.clipboard.writeText(tmpupass);

  const file = new Blob([txt], {
    type: "text/plain"
  });
  element.href = URL.createObjectURL(file);
  element.download = appConfig.apiname + "_" + datecode + ".rdp";
  document.body.appendChild(element);
  element.click();


};





  


function goReset() {

  const input = prompt("Enter 'yes' below to confirm DATA ERASE / SERVER SOFTWARE RESET");
  if (input === null) { 
   // alert("Reset Cancelled")
  } else if( input === 'yes' ) {
    setLoadTic(0);resetButton(); setLoader("resetting");
  
  }


};

function resetButton() {
  var raw = JSON.stringify({"uname": props.value,"act": "reset_"+ appConfig.apiname});

 var myInit = {
  body: raw,
  headers: {
    'Content-Type': 'application/json'}, 
    redirect: 'follow',
};
API.post(apiName, path, myInit)
.catch((error) => {
  alert(appConfig.appname + " RESET API ERROR: " + error.message + ' ' + error.response)  
});;

};




function goBackup() {

  setLoadTic(0);
    backupButton(); 
    setLoader("backing up");

};

function backupButton() {
  var raw = JSON.stringify({"uname": props.value,"act": "backup_"+ appConfig.apiname});

 var myInit = {
  body: raw,
  headers: {
    'Content-Type': 'application/json'}, 
    redirect: 'follow',
};
API.post(apiName, path, myInit)
.catch((error) => {
  alert(appConfig.appname + " BACKUP API ERROR: " + error.message + ' ' + error.response)  
})

};


function goRestore() {

  const input = prompt("Enter 'yes' below to confirm DATA ERASE / RESTORE FROM BACKUP");
  if (input === null) { 
   
  } else if( input === 'yes' ) {
    setLoadTic(0);restoreButton(); setLoader("restoring");
  
  }


};

function restoreButton() {
  var raw = JSON.stringify({"uname": props.value,"act": "restore_"+ appConfig.apiname});

 var myInit = {
  body: raw,
  headers: {
    'Content-Type': 'application/json'}, 
    redirect: 'follow',
};
API.post(apiName, path, myInit)
.catch((error) => {
  alert(appConfig.appname + " RESTORE API ERROR: " + error.message + ' ' + error.response)  
})

};


  




  function copyClip() {
 
   
      if (fact2 === "") {
   //     var tmpuip = props.uip;
        var tmpupass = props.upass;
      } else {
        var tmpstr = fact2.substring( fact2.indexOf("|") + 1,fact2.lastIndexOf("~") );
   //     tmpuip = tmpstr.split("!")[17];
    
       
        var tmp = tmpstr.split("!<<<<")[1];
        tmpupass = tmp.substring( tmp.indexOf("<<<<") + 1,tmp.lastIndexOf(">>>>") );
      };
    
     navigator.clipboard.writeText(tmpupass);
    
    
    };
    



  if (fact2 === "") {

    var tmpstr = ""

  } else {
    tmpstr = fact2.substring( fact2.indexOf("|") + 1,fact2.lastIndexOf("~") );
//alert("using ST Fact ")

  };


 
 var tmpUspin=false;
  

 
 var tmpBClass = 'BK-button';
 var tmpDClass = 'BK-button';
 

 var tmpDstatus = "";
 
 var tmpDbutton = "";
 var tmpUstatus = "";
 
 var tmputos = props.utos; 
 var tmputos2 = props.utos;
 var tmpustanding = props.ustanding;
 var tmpulastbackup = props.ulastbackup;

 var tmpureg = props.ureg;

 var tmpuip = props.uip;

 var tmpstat = props.ustat;
 var tmpstat2 = tmpstat;
 var tmpstate = props.ustate;
//var tmpbank = props.ubank;
var tmpdet = props.udet;
var tmpstate2 = tmpstate;

var tmpupass = props.upass;
var tmpuready = props.uready
//var tmplaststart = props.laststart

if (tmputos === "--") {

} else 

{

if (tmpstr !== "") { 
//alert("not blank");


   tmpstat = tmpstr.split("!")[0];

  tmpstat2 = tmpstat;
  tmpuready = tmpstr.split("!")[1];
  
    tmpdet = tmpstr.split("!")[3];

    tmpstate = tmpstr.split("!")[4];
   tmpstate2 = tmpstate;

     tmputos = tmpstr.split("!")[7];
     tmpustanding = tmpstr.split("!")[5];
     tmpulastbackup = tmpstr.split("!")[6];
 

     tmpureg = tmpstr.split("!")[10];
   
     tmpuip = tmpstr.split("!")[17];

     
    var tmp = tmpstr.split("!<<<<")[1];
    var tmpupass = tmp.substring( tmp.indexOf("<<<<") + 1,tmp.lastIndexOf(">>>>") );


  } else {
    //tmpstr was empty
  // alert("blank")
  };
    

    tmpDstatus = tmpstat
    


    if (tmpstat2 === "Start Failed" ) {
      //ERROR OVERRIDE

      tmpBClass = 'PR-button';
      tmpUspin=true;
      tmpDClass = 'App-processing';
      
      //tmpUbank="";
  
      tmpDstatus="Start Error (" + tmpdet +  ")";
      //tmpUbank="Admin has been notified";
      
      tmpDbutton='ERROR' ;
      tmpUspin='false';

    } else if (tmpstat2 === "Stop Failed" ) {
        //ERROR OVERRIDE
  
        tmpBClass = 'PR-button';
        tmpUspin=true;
        tmpDClass = 'App-processing';
        
       // tmpUbank="";
   
        tmpDstatus="Stop Error (" + tmpdet +  ")";
        //tmpUbank="Admin has been notified";
        
        tmpDbutton='ERROR' ;
        tmpUspin='false';



    } else {

     // var tmpLoader = "";


    if (loader === "starting" ) {

   
    
      if (tmpstat2 === "Starting" || tmpstat2 === "Running") {
        //clear loader
        setLoader("");
       // tmpLoader = "" ;
        
        
   //loader override

      tmpBClass = 'PR-button';
      tmpUspin=true;
      tmpDClass = 'App-processing';
      
      //tmpUbank=dispTime(tmpbank);
      
      tmpstate = "starting";
      //alert(tmpstat);
      tmpstat = "DATA1B";
      tmpDstatus="Starting";
    
      

     
      } else {
      
      //loader override

      
        if (loadTic >= 4) {
          //Output Loader Error

          tmpBClass = 'PR-button';
          tmpUspin=true;
          tmpDClass = 'App-processing';
          
          tmpstate = "starting";
          
          tmpstat = "DATA1AE";
          tmpDstatus="Error - Start Unprocessed";
          
        } else {

          tmpBClass = 'PR-button';
          tmpUspin=true;
          tmpDClass = 'App-processing';
          
          
          
          tmpstate = "starting";
          
          tmpstat = "DATA1A";
          tmpDstatus="Starting";
          
          
        }


      };
        
  
    } else if (loader === "stopping") {
  
      if (tmpstat2 === "Stopping" || tmpstat2 === "Stopped") {
        //clear loader
        setLoader("")
        //tmpLoader = "" ;

            //loader override

      tmpBClass = 'PR-button';
      tmpUspin=true;
      tmpDClass = 'App-processing';
      
      
      
      tmpstate = "stopping";
      
      tmpstat = "DATA2B";
      tmpDstatus="Stopping";
      
      

      } else {

      //loader override


      if (loadTic >= 4) {
        //Output Loader Error

        tmpBClass = 'PR-button';
        tmpUspin=true;
        tmpDClass = 'App-processing';
      
        tmpstate = "stopping";
      
        tmpstat = "DATA2AE";
        tmpDstatus="Error - Stop Unprocessed";
      
      } else {

      tmpBClass = 'PR-button';
      tmpUspin=true;
      tmpDClass = 'App-processing';
      
      
      
      tmpstate = "stopping";
      //alert(tmpstat);
      tmpstat = "DATA2A";
     tmpDstatus="Stopping";
      };
      
      };
  
     } else {

      if (loader === "resetting") { 
        //loader ovveride cooldown
        tmpstat = "DATA2C";
        tmpstate = "resetting";

        if (tmpdet === "Reset") {
          //clear loader
          setLoader("")
        //  tmpLoader = "" 
        } else {
          if (loadTic >= 4) {
            tmpDstatus="Error - Reset Unprocessed";
          }
         }
      };


      if (loader === "backing up") { 
        //loader ovveride cooldown
        tmpstat = "DATA2D";
        tmpstate = "backing up";

        if (tmpstat2 === "Backing Up") {
          //clear loader
          setLoader("")
         // tmpLoader = ""
         } else {
          if (loadTic >= 4) {
            tmpDstatus="Error - Backup Unprocessed";
          }
         }
      };

     if (loader === "restoring") { 
        //loader ovveride cooldown
        tmpstat = "DATA2E";
        tmpstate = "restoring";

        if (tmpdet === "Restore") {
          //clear loader
          setLoader("")
        //  tmpLoader = "" 
        } else {
          if (loadTic >= 4) {
            tmpDstatus="Error - Restore Unprocessed";
          }
         }
      };




     };




/////////////////////////////////////////Main 




  if (tmpstate === "starting" ) {
    //Starting State 
    
    if (tmpstat === "Stopped" ) {
      // skip
      tmpDstatus ="ERROR";
    } else if (tmpstat === 'Running') {
 
      tmpDbutton='STOP' ;

      tmpDClass = 'App-running';
      tmpBClass = 'SP-button';
      
      tmpUstatus=tmpstat; 


      
      tmpUspin =false;
  
    } else if (tmpstat === "") {
      // skip
      tmpDstatus ="ERROR";
    } else {
  
      tmpDbutton='WAITING';
     
      tmpBClass = 'PR-button';
      
      tmpDClass = 'App-processing';

      tmpUstatus=tmpstat;

      tmpUspin=true;

    };

  } else if (tmpstate === "resetting") {
    
      tmpDbutton='WAITING';
      
      tmpBClass = 'PR-button';
      
      tmpDClass = 'App-processing';
      tmpUstatus=tmpstat; 
      tmpDstatus="Resetting"
      
      tmpUspin=true;


    } else if (tmpstate === "backing up") {
    
      tmpDbutton='WAITING';
      
      tmpBClass = 'PR-button';
      
      tmpDClass = 'App-processing';
      tmpUstatus=tmpstat; 
      tmpDstatus="Backing Up"
      
      tmpUspin=true;


    } else if (tmpstate === "restoring") {
    
      tmpDbutton='WAITING';
      
      tmpBClass = 'PR-button';
      
      tmpDClass = 'App-processing';
      tmpUstatus=tmpstat; 
      tmpDstatus="Restoring"
      
      tmpUspin=true;



  } else if (tmpstate === "stopping") {

    if (tmpstat === "Stopped" ) {


      if (tmputos2 === "1") {


        if (tmpustanding ==="enabled" && tmpureg !== "not set"  && tmpuready !== 'no' ) {
   
        tmpDbutton='START' ;
       
        tmpDClass = 'App-stopped';
        tmpBClass = 'ST-button';
     tmpDstatus= tmpstat;
        tmpUstatus=tmpstat; 
    
        tmpUspin=false;
        //tmpUmessage="";
        
        
        //tmpUmessage=tmpitype
      } else if (tmpureg ==="not set") {
        //Region not set
        tmpDbutton='DISABLED';
       
        tmpBClass = 'PR-button';
       
        tmpDClass = 'App-processing';
        tmpUstatus=tmpstat; 
       
       tmpDstatus="Please select a Datacenter Location";
       
        tmpUspin=false;

        
      } else if (tmpuready === "no" ) {
        //NOT READY OVERRIDE
  
        tmpBClass = 'PR-button';
        tmpUspin=true;
        tmpDClass = 'App-processing';
        
       // tmpUbank="";
   
        tmpDstatus="Creating Account";
        //tmpUbank="Admin has been notified";
        
        tmpDbutton='WAITING' ;
        tmpUspin='false';
        
       } else {


          tmpDbutton='DISABLED';
       
          tmpBClass = 'PR-button';
       
          tmpUstatus=tmpstat; 
       
            tmpUspin=false;
       
       tmpDClass = 'App-processing';
            tmpDstatus="Please subscribe via PayPal to activate account";
     

        };
       } else if (tmputos2 ==="0") {
   
        tmpDbutton='DISABLED';
        
        tmpBClass = 'PR-button';
        
        tmpDClass = 'App-processing';
        tmpUstatus=tmpstat; 
   
       tmpDstatus="Please accept the 'Terms Of Service' and 'Privacy Policy'";
   
        tmpUspin=false;
        

       } else {
        alert("ERROR - missing tos value (" + tmputos + ")")

      };

     } else if (tmpstat === 'Running') {
     // skip
      
     } else if (tmpstat === "") {
      // skip


      
     } else if (tmpstat === "Bad Account Name") { 
      alert('Bad Account ' + props.value + " " + tmpstat)

      tmpDstatus="Loading Account " + props.value + "/" + uname;


     } else if (tmpstat === "No Account Found") {




    
      tmpDbutton='DISABLED';
    
      tmpBClass = 'PR-button';
    
      tmpDClass = 'App-processing';
      tmpUstatus=tmpstat; 
    
      tmpUspin=false;
      tmpDstatus="No Account Found. Please request access"



     } else {

      tmpDbutton='WAITING';

      tmpBClass = 'PR-button';

      tmpDClass = 'App-processing';
      if (appConfig.burnOn === "true") {tmpDstatus="Destroying PC"};
      tmpUstatus=tmpstat;

      tmpUspin=true;

     };
  
   
   

  
  } else {
  //  alert("error")
  };


}


}






 
   //var tmpCol = '#FECD45'
   var tmpCol = 'white'


    return (


      
        <>
          
      

       <div style={{ color: '#FECD45' , textAlign: "center", fontWeight: 'bold' , fontSize: 'calc(5px + 1vw)' }}> 
       
       {tmputos2 === "1" ?     (


        tmpustanding ==="enabled" ? (<><br></br>
          <span style={{ color: '#FECD45' , textAlign: "center", fontWeight: 'bold' , fontSize: '2.3vw' }} >SERVER STATUS</span><br></br> <span className = {tmpDClass} style={{ textAlign: "center", fontWeight: 'bold' , fontSize: '2.3vw' }}  >{tmpDstatus}</span>
        </>) : (<><br></br>
          <span style={{ color: '#FECD45' , textAlign: "center", fontWeight: 'bold' , fontSize: '2.3vw' }} >SERVER SETUP</span><br></br><br></br> <span className = {tmpDClass} style={{ textAlign: "center", fontWeight: 'bold' , fontSize: '2.3vw' }}  >{tmpDstatus}</span>

          
          
        </> )


       
        ) :(<><br></br>
        <span style={{ color: '#FECD45' , textAlign: "center", fontWeight: 'bold' , fontSize: '2.3vw' }} >SERVER SETUP</span><br></br><br></br> <span className = {tmpDClass} style={{ textAlign: "center", fontWeight: 'bold' , fontSize: '2vw' }}  >{tmpDstatus}</span>
       </>) }


</div> 
  
         



{ tmpUspin === true ? (<>
  <Flex 
      
    direction="column"
    paddingTop="1vw"
    
    minHeight="8vw"
    textAlign="center"
    alignItems="center"
    justifyContent= "center"
    >     
      
      <img src={logo} className="App-logo" alt="logo" />
      
      </Flex>
</>):(<>


  {tmpustanding ==="enabled"  ? (<>
  
    <Flex 
      
      direction="column"
      minHeight="0vh"
      paddingTop="8vw" //NOOOOOOO Change
      textAlign="center"
      alignItems="center"
      justifyContent= "center"
      >     
  
    </Flex>

  </>):(<>
  
    <Flex 
      
      direction="column"
      minHeight="0vh"
      paddingTop="0vw" //NOOOOOOO Change
      textAlign="center"
      alignItems="center"
      justifyContent= "center"
      >     
  
    </Flex>
  
  </>)}





</>)}


<Flex
         direction="column"
         textAlign="center"
         alignItems="center"
         fontSize= "calc(5px + 1vw)"
         gap="0px"
        

        >

      {tmputos2 === "1" ? (
      
      tmpustanding ==="enabled" ? (<>

<Flex
         direction="column"
         textAlign="center"
         alignItems="center"
         fontSize= "calc(5px + 1vw)"
         gap="0px"
         minHeight="15vw" ////////////////////////////////////////// <<<<<<<<<<<<<<<<

        >

        
          <span style={{ paddingTop:'2vw', color: '#FECD45' , textAlign: "center", fontWeight: 'bold' ,  fontSize: '2.3vw' }}  >SERVER CONTROLS</span>

          <button className = {tmpBClass} style={{ border: "none", borderRadius: '30px' ,  display: "inline-block", marginTop: "1.2vw", fontWeight:'bold', fontSize: '1.3vw',  height: "auto", width: "10vw"  }} onClick={goButton}>{tmpDbutton}</button>



          {tmpUstatus === "Stopped" && tmpustanding ==="enabled" ? ( <> 
          <button className = 'Misc-button' style={{ border: "none", borderRadius: '30px', display: "inline-block", fontSize: '1.3vw', fontWeight: 'bold', marginTop: "1vw", height: "auto", width: "10vw" }}  onClick={goOpt1}>OPTIONS</button>
        </> ) : (<>
        
        
          {tmpUstatus === "Running" ? ( <>  
  
  <button className = 'RDP-button' style={{ border: "none", borderRadius: '30px' ,  display: "inline-block", marginTop: "1vw", marginBottom: "1vw", fontWeight:'bold', fontSize: '1.3vw',  height: "auto", width: "10vw"  }} onClick={handleClickToOpen}>CONNECT</button> 
  </>
    ) : (             <></>     )}
        
        </>)}

          </Flex>
        

        </>):(<></>)
      
      
      ):(<>
      
    
      
      </>) }
         


 
      { tmpUspin === 'disabled' ? (<><Flex 
      
      direction="column"
      //marginLeft='10px'
      //marginRight='10px'
     // wrap='wrap'
     // gap='10px'
 //     marginTop='15px'
  //    marginBottom='calc(15px + 6px)'
  paddingTop="15px"
  //paddingBottom="9px"
     textAlign="center"
     alignItems="center"
     justifyContent= "center"
    // gap = '0px'
     //minHeight={appConfig.minHeightD }
 
     >     
      
      <img src={logo} className="App-logo" alt="logo" />
      
      </Flex>
      

      
      
      </> ) : ( 
        
  
        tmputos2 === "1" ? (<>
        
         
  
        </>   ) : (<></>) 
  
  
        
         ) }

</Flex>

       
 
 <div stlye={{}}>

      <Dialog   style={{fontSize:'1vw'}} open={open} onClose={handleToClose}>
        <DialogTitle style={{ fontSize:'1.5vw' , textAlign:"center", backgroundColor:tmpCol }}>{"RDP CONNECTION INFO"}</DialogTitle>
        <DialogContent style={{backgroundColor:tmpCol,fontSize:'1vw' }} >
          <DialogContentText style={{fontSize:'1vw'}} >
 
          <span style={{fontSize:"1vw", color: "black"}}>IP Address: {tmpuip}<br></br> User: Administrator <br></br>Password: {tmpupass} <br></br><br></br>Click 'Copy' to copy the password to the clipboard.<br></br>Click 'Download' to download hte RDP Connection Shortcut.</span>
          
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ backgroundColor:tmpCol , fontSize:'1vw' }}>
        
        <Button  style={{ fontSize:'0.9vw'}}  onClick={copyClip} 
                  color="primary" autoFocus
                 // variant="outlined"
                   >
            
             Copy
          </Button>
        <Button  style={{ fontSize:'0.9vw'}}  onClick={downloadTxtFile} 
                  color="primary" autoFocus
                 // variant="outlined"
                   >
            
            
             Download
          </Button>
          <Button  style={{ fontSize:'0.9vw'}}  onClick={handleToClose} 
                  color="primary" autoFocus
                //  variant="outlined"
                 >
            Close
          </Button>
        </DialogActions>
      </Dialog>





           
      <Dialog open={open4} onClose={handleToClose4}>
        <DialogTitle style={{fontSize:'1.5vw' , textAlign:"center", backgroundColor:tmpCol}}>SERVER OPTIONS</DialogTitle>
        <DialogContent style={{backgroundColor:tmpCol}} >
          <DialogContentText>
          <Flex 
          direction="column"
          textAlign="center"
          alignItems="center"
          color="black"
          minWidth="18vw"
          
          maxHeight="calc(16vw - 15px)"
          gap="0.5vw"
          paddingTop="1vw"
          fontSize="1vw"
          >
            
{ tmpdet === "Reset" ? (<>
  <button className = 'optBtn' style={{  fontSize:'1vw', borderColor:'grey', backgroundColor:'grey', color:'white', width: "10vw" }} onClick={goBlank}>RESET</button>
  </>):(<>
    <button className = 'optBtn' style={{fontSize:'1vw',  borderColor: '#9A031E', backgroundColor:'#9A031E', color:'white', width: "10vw" }} onClick={handleToClose4a}>RESET</button>
  </>)}


            
            
            <button className = 'optBtn' style={{ marginTop:'2vw',  fontSize:'1vw',borderColor:'black' , backgroundColor:'black', color:'white', width: "10vw" }} onClick={handleToClose4b}>BACKUP</button>


            { tmpulastbackup === "None" ? (<>
              <button className = 'optBtn' style={{ fontSize:'1vw', borderColor:'grey',  backgroundColor:'grey', color:'white', width: "10vw" }} onClick={goBlank}>RESTORE</button>
            </>):(<>
              <button className = 'optBtn' style={{ fontSize:'1vw', borderColor:'black',  backgroundColor:'black', color:'white', width: "10vw" }} onClick={handleToClose4c}>RESTORE</button>
            </>)}

           
            <br></br>Backup Date<br></br> {tmpulastbackup}
            
            
            </Flex>
          </DialogContentText>
          
     
        
        </DialogContent>
        <DialogActions style={{ backgroundColor:tmpCol }}>
          <Button style={{ fontSize:'0.9vw'}} onClick={handleToClose4}>Exit Options</Button> 
          
        </DialogActions>
      </Dialog>




      <Flex 
position='fixed' 
bottom='2.5vh'
left='1vw'>
     <span hidden = {appConfig.debugHide} style={{ color: 'grey' , textAlign: "center", fontWeight: 'bold' , fontSize: '1.5vh' }} > 
STIME: tic({tic}-{counter}) skf({skipFact}/2) tos({tmputos})-2({tmputos2}) us({tmpustanding}) ureg({tmpureg}) det({tmpdet}) STATUS-({tmpstat}-2({tmpstat2})-{tmpstate}-2({tmpstate2}) urdy({tmpuready}) Ldr({loader}) Ltc({loadTic}) pw({tmpupass})) 


 {
 //<button onClick={notify}>Notify</button>
}
</span>
 
</Flex>

<Flex 
position='fixed' 
bottom='0vh'
left='1vw'>
     <span hidden = {appConfig.debugHide} style={{ color: 'grey' , textAlign: "center", fontWeight: 'bold' , fontSize: '1.5vh' }} > 
     FACT2: {fact2}
</span>
</Flex>
    

    </div>

   



       
         </>
      );
    



    
}
    export default StatusTime;

