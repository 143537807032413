import { Amplify,API } from "aws-amplify";
import React, { useState, useEffect } from 'react';
import './App.css';

import { withAuthenticator } from "@aws-amplify/ui-react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { SignInHeader } from "./SignInHeader";
import { SignUpHeader } from "./SignUpHeader";
import { SignInFooter } from "./SignInFooter";
//import { SignUpFooter } from "./SignUpFooter";
import "./styles.css";

import Dialog from "@mui/material/Dialog";



import logo from './785.svg';
import StatusTime from "./StatusTime";
import Body from "./Body";
import appConfig from './AppConfig';

//import background from "./ncs1f2.jpg";
import {
  Flex,
} from "@aws-amplify/ui-react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
//import { Authenticator } from '@aws-amplify/ui-react';


import awsExports from "./aws-exports";
Amplify.configure(awsExports);



//const path = window.location.path;
//const authState = 'signUp';

if (window.location.pathname === '/signup/' )  {
   var tmpAuthState = "signUp";
   var tmpHideSignUp = false
} else {
   tmpAuthState = "signIn";
   tmpHideSignUp = true
};








export function App({ signOut, user }) {







  const apiName = 'apibc7ea7ab'; // replace this with your api name.
  const path = '/items'; //replace this with the path you have configured on your API
  
  
   // const [uname, setUname] = useState(user.attributes.email);
    const [ustat, setUstat] = useState("--");
    const [ustate, setUstate] = useState("--");
    
    const [udet, setUdet] = useState("--");
    
    const [upass, setUpass] = useState("--");
  
   const [ulastbackup, setUlastbackup] = useState("--");
  
    const [utos, setUtos] = useState("--");

    const [ureg, setUreg] = useState("--");
    const [uready, setUready] = useState("--");

    const [uclass, setUclass] = useState("--");

    const [uip, setUip] = useState("--");
    const [ustanding, setUstanding] = useState("--");


    const [uerr, setUerr] = useState("Connecting to Service...");
  
    
    function getDat(res) {
  
      var tmpstr = res.substring( res.indexOf("|") + 1,res.lastIndexOf("~") );
      setUerr('0');
  
      var tmpstat = tmpstr.split("!")[0];
      var tmpuready = tmpstr.split("!")[1];
      var tmpustanding = tmpstr.split("!")[5];
      var tmpulastbackup = tmpstr.split("!")[6];
      var tmputos = tmpstr.split("!")[7];
      
      var tmpureg = tmpstr.split("!")[10];
      var tmpuclass = tmpstr.split("!")[12];
      
      var tmpuip = tmpstr.split("!")[17];
      
      var tmpdet = tmpstr.split("!")[3];
      var tmpstate = tmpstr.split("!")[4];
      
      var tmp = tmpstr.split("!<<<<")[1];
      var tmpupass = tmp.substring( tmp.indexOf("<<<<") + 1,tmp.lastIndexOf(">>>>") );
  
      setUstat(tmpstat);
      setUstate(tmpstate);

      setUdet(tmpdet);
      setUstanding(tmpustanding);
      setUtos(tmputos);

      setUreg(tmpureg);
      setUready(tmpuready);
      setUclass(tmpuclass);

      setUip(tmpuip);

     setUpass(tmpupass);
     setUlastbackup(tmpulastbackup)

    };
  
  
  
    useEffect(() => {
  
      async function statusButton2() {

         var raw = JSON.stringify({"uname": user.attributes.email,"act": "status_"+ appConfig.apiname});
  
       var myInit = {
        body: raw,
        headers: {
          'Content-Type': 'application/json'}, 
          redirect: 'follow',
      };
      API.post(apiName, path, myInit)
       .then(response => getDat(response) )
       .catch((error) => goError("Connection Error(1): " + error.message))
  
       };
  
  
  
  
      //checkUser();
      statusButton2();
      
      window.history.pushState({}, null, "/");

    }, []);
  
  
  
   
  
    useEffect(() => {
      document.title = 'Nano Cloud Servers';
      }, []);
  
  
  
    
      function goHelp() {
        //Open help/support page in new window
         window.open("https://" + appConfig.websitename + "/help");
      }


  
    function goError(err) {
  
      setUerr(err);
     //alert(appConfig.appname + "ERROR!! (214) " + err);
  
  
    };
  
  var fact2 = "";
  

  if (uerr === '0') {
   var hideErr = true;
   var hideBody = false;
   var hideSpin = true;
  } else {
    hideErr = false;
    hideBody = true;
  
    if (uerr === 'Connecting to Service...') {
      hideSpin = false;
      var eclass = "App-stopped";
      var dispuerr = "";
    } else {
      hideSpin = true
      eclass = "App-processing";
      dispuerr = uerr;
    }
  
  };
  
  
  


  return (
    

    <main>


     
	<div style={{   color: '#FECD45' ,   minWidth: "100vw", minHeight: "100vh",  backgroundColor: 'black' , backgroundImage: `url(${""})`,  backgroundRepeat:"no-repeat",backgroundPosition:"center top",backgroundSize:"100vw", height:'auto'   }}>

<div className='navBarBox' >   




<Flex
direction="row"
justifyContent="space-between"
alignItems = "center"
textAlign="center"
gap="0"
maxWidth='100vw'
//minWidth='100vw'
>


<Flex
justifyContent="start"
direction="row"
minWidth='25.5vw'
maxWidth='25.5vw'
 //boxShadow= "inset 0 0 0 1px grey"
paddingLeft='2vw'
 wrap="wrap"
 gap="1.5vw"
  >



<button  className = 'navBarLink3'  onClick={goHelp}>HELP</button>

 
        <a className = "navBarLink3" style={{ textDecoration: 'none' }} href="mailto:support@nanocloudservers.com">CONTACT</a> 
		 	  

  </Flex>
  
  
<Flex
	direction="column"
	textAlign="center"
	//boxShadow= "inset 0 0 0 1px red"
	gap="0"
	//maxWidth='40vw'
	minHeight='13vw'
	>
     <span className = "navBarTitle" style={{ textDecoration: 'none' }} >NANO<span  style={{ color: '#2568FB' }}  >CLOUD</span>SERVERS</span>
  
  
  
  
      <span  className='Mid-bar' >Nano Server Control Panel<br></br>{user.attributes.email}</span>
  
     </Flex>
  
  
 
     <Flex
	 justifyContent="end"
	 direction="row"
	 minWidth='25.5vw'
	 maxWidth='25.5vw'
	  //boxShadow= "inset 0 0 0 1px grey"
	 paddingRight='2vw'
	  wrap="wrap"
	  gap="1.5vw"
	   >
  <button className = 'navBarLink3' onClick={signOut}>LOG OUT</button>
    
  </Flex>
      
  </Flex>
  
  </div>
  
  
  







 <Flex
 direction="column"
 textAlign="center"
 alignItems="center"
 gap="15px"
 //maxHeight="10vh"
 >
      
      <div className = "App" style={{ fontSize: "calc(5px + 2vw)", display: "inline",  fontWeight: "normal",color: "white",  border: "4px" , borderStyle: "none" , borderColor: "black" ,  textAlign: "center" , minHeight: '70vh' }} >
      
      
<span hidden={hideErr} className = {eclass}>
<Flex  
direction="column"
textAlign="center"
alignItems="center"
gap='0px'
> 



<span style={{fontSize: 'calc(5px + 2.4vw)'}}>
{dispuerr}
</span>
<span hidden={hideSpin} >
<Flex 
      
      direction="column"
  paddingTop="20vw"
  //paddingBottom="9px"
     textAlign="center"
     alignItems="center"
     justifyContent= "center"
     gap = '0px'
     //minHeight={appConfig.minHeightD }
     >     
      
      <img src={logo} className="App-logo" alt="logo" />
      
      </Flex>
      </span>

</Flex>
</span>


<span hidden={hideBody} >
      <Flex
         direction="column"
         textAlign="center"
         alignItems="center"
        gap='0'
        minHeight={appConfig.minHeightB}
         
        >

  
     <Flex
        direction="column"
        textAlign="center"
        alignItems="center"
        borderRadius='5vw'
     boxShadow= "inset 0 0 0 .5vh #FECD45"
    minWidth="50vw"
    maxWidth="50vw"
  //minHeight="55vh"
  //maxHeight="55vh"
     marginTop='3vw'
     //paddingTop='2vw'
     //paddingBottom='4.5vw'
     paddingLeft='10vw'
     paddingRight='10vw'
     gap='0px'
     >


{
      <StatusTime value={user.attributes.email} ulastbackup={ulastbackup} utos={utos} ustat={ustat} ustate={ustate} udet={udet} ustanding={ustanding} ureg={ureg} uready={uready} upass={upass} uclass={uclass} uip={uip} fact={fact2} funct={setUstanding}  />
}
     

            {
             <Body uname={user.attributes.email} utos={utos} ustanding={ustanding} funct={setUstanding} funct2={setUtos} funct3={setUreg} ustatus={ustat} ureg={ureg}  />
      }

      </Flex>


      </Flex>
      </span>     

 

    
           

      

      </div>
      

    

        </Flex>


    

      <ToastContainer
      //position="top-right"
      //progressClassName="toastProgress"
      //bodyClassName="toastBody"
      position="bottom-center"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      //theme="dark"
      //theme="light"
      theme="colored"
      icon={true}
      >
      </ToastContainer>
  


    
    



    </div>








    <Flex 
position='fixed' 
bottom='7.5vh'
left='1vw'>
      <span hidden = {appConfig.debugHide}  style={{ color: 'grey' , textAlign: "center", fontWeight: 'bold' , fontSize: '0.8vw' }} >APP: us({ustanding}) ureg({ureg}) utos({utos}) urdy({uready}) </span> 
</Flex>





    </main>
    

  );
}

export default withAuthenticator(App, {
  initialState:tmpAuthState,
  hideSignUp:tmpHideSignUp,
  
  components: {
    
    Header,
    SignIn: {
      Header: SignInHeader,
      Footer: SignInFooter
    },
    SignUp: {
      Header: SignUpHeader,
      
    },
    Footer
  }
});
